*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Mont-Regular',sans-serif;
}
@font-face {
  font-family: Mont-Bold;
  src: url(../../assests/fonts/Mont-Bold.ttf);
}
@font-face {
  font-family: Mont-Light;
  src: url(../../assests/fonts/Mont-Light.ttf);
}

@font-face {
  font-family: Mont-Regular;
  src: url(../../assests/fonts/Mont-Regular.ttf);
}

@font-face {
  font-family: Mont-SemiBold;
  src: url(../../assests/fonts/Mont-SemiBold.ttf);
}
@font-face {
  font-family: Mont-Heavy;
  src: url(../../assests/fonts/Mont-Heavy.ttf);
}
@font-face {
  font-family: ArticulatCF-Medium;
  src: url(../../assests/fonts/ArticulatCF-Medium.otf);
}
@font-face {
  font-family: ArticulatCF-Regular;
  src: url(../../assests/fonts/ArticulatCF-Regular.otf);
}
@font-face {
  font-family: ArticulatCF-Normal;
  src: url(../../assests/fonts/ArticulatCF-Normal.otf);
}
@font-face {
  font-family: ArticulatCF-Light;
  src: url(../../assests/fonts/ArticulatCF-Light.otf);
}
@font-face {
  font-family: ArticulatCF-Thin;
  src: url(../../assests/fonts/ArticulatCF-Thin.otf);
}
html {
  scroll-behavior: smooth;
}
/*---------------------- header section ----------------------- */
#header .navbar-nav a{
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
   padding: 16px 22px;
    line-height: 28px;
}
#header .navbar-nav a:hover, .slider_home .carousel-control-prev:hover, .slider_home .carousel-control-next:hover{
	 background: #277f9a;
}
#header .dropdown:hover>.dropdown-menu {
  display: block;
}

#header{
	position: absolute;
    z-index: 100;
    width: 100%;
}
#header .navbar-nav,.dropdown-menu{
    background: #70707094 0% 0% no-repeat padding-box;
}

/* end header section */



/*----------------------  Home design ---------------------------------*/

.slider_home .carousel-control-next-icon, .slider_home .carousel-control-prev-icon{
width: 1rem;
    height: 1rem;
}
.slider_home .carousel-control-prev, .slider_home .carousel-control-next{
	    width: 35px;
    height: 35px;
    background: #707070e0;
    top: 30%;
        opacity: 1;
}
.slider_home .carousel-control-prev{
	    left: 15%;
}
.slider_home .carousel-control-next{
	    left: 18%;
}
.slider_home .carousel-caption{
    left: 15%;
    top: 35%;
    text-align: left;
    width: 45%;
}
.slider_home .carousel-caption h2{
	font-size: 90px;
	text-transform: uppercase;
     font-family: Mont-Heavy;
}
.slider_home .carousel-caption p{
	font-family: Mont-Heavy;
	font-size: 31px;
}
.full-page-img {
	  background-image: url('../../assests/images/slider1.PNG');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
      background-size: cover;
}

.heading-section h2{
	font-family: Mont-Heavy;
	font-size: 38px;
	color: #fff;
	text-align: center;
}
.heading-section p{
	     font-family: Mont-SemiBold;
    font-size: 16px;
    color: #fff;
    margin-left: 12%;
    margin-right: 15%;
    text-align: center;
}
.solution-div{
	background: #00000063;
	padding: 130px 0;

}
.feature-div{
	background: #f5f5f536 0% 0% no-repeat padding-box;
	margin-top: 100px;
}
.feature-div{
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px;
    border-radius: 20px;
}
.text-feature{
	flex-basis: 44%;
}
.img-feature{
	flex-basis: 40%;
}
.text-feature h2{
  font-size: 40px;
  font-family: Mont-Heavy;
}
.dark-blue{
  color: #6256c7;
}
.text-feature p{
	color: #fff;
	font-size: 14px;
	  font-family: Mont-SemiBold;
}
.img-feature img{
	    border-radius: 20px;
    width: 100%;
}
.offer-a{
	line-height: 29px;
	padding: 8px 52px;
	border-radius: 23px;
	text-decoration: none;
	color: #fff;
	font-family: sans-serif;
	font-size: 14px;
	font-weight: 700;
}
.bg-blue{
background: #6256c7;
	
}
.bg-blue:hover,.bg-blue:focus{
	background: #38316d;
	color: #fff;
}
.solution-div .feature-div+.feature-div{
    margin-top: 50px;
}
.effect-border{
	  border: 20px solid transparent;
	  border-radius: 20px;
	   transition: 0.5s all ease-in-out;
}
.effect-border:hover{
 border-radius: 20px;
    opacity: 0.9;
    transform: scale(0.9);
}
 .blue-imgb:hover{
  border: 20px solid #6256C7;
}
.orange-color{
color: #e6881d;
}
.bg-orange{
	background: #e6881d;
}
.bg-orange:hover,.bg-orange:focus{
	background: #945917;
	color: #fff;
}
.orange-imgb:hover{
  border: 20px solid #e6881d;
}
.sky-color{
	color: #2fd2f2 !important;
}
.bg-sky{
background: #2fd2f2;
}
.bg-sky:hover, .bg-sky:focus{
background: #277f9a;
color: #fff;
}
.sky-imgb:hover{
  border: 20px solid #2fd2f2;
}

.location-banner{
	background-image: url(../../assests/images/location.PNG);
	background-repeat: no-repeat;
	background-size: cover;
}
.location-section{
	padding: 140px 0;
}
.offering-section{
	background: #00000063;
}
.offer-section h2{
	    font-family: sans-serif;
    font-weight: 900;
    font-size: 46px;
}
.cash-out{
	background-image: url(../../assests/images/offering.PNG);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
}
.cash-section{
	padding: 110px 0;
	width: 53%;
}
.cash-section h2,.cash-section p{
	text-align: left;
	margin-left: 0;
}
.cash-section ul{
	color: #fff;
	  list-style: none;
}

.cash-section ul li {
  position: relative;
  padding-left: 1.5em;
}

.cash-section ul li::before {
  content: '';
  position: absolute;
  left: 0; 
  width: 1em;
  height: 1em;
  background: url("../../assests/images/check-icon.svg") no-repeat;
}
.upar-footer h2,.upar-footer p{
  text-align: left;
  margin-left: 0;
}
.upar-footer h2{
	color: #277f9a;
	font-size: 46px;
}
.upar-footer p{
	color: #707070;
	font-size: 18px;
}

.bett_div{
	background: #fff;
	padding: 130px 0;
}


.items {
    width: 90%;
    margin: 0px auto;
}

.slick-slide {
    margin: 10px
}

.slick-slide img {
    width: 100%;
    border: 0px solid #fff
}
.upar-footer p{
 color: #707070;
 font-size: 16px;
 font-family: sans-serif;
 font-weight: 400;
 margin-right: 0;
}
.barcode {
  text-align: center;
}
.barcode  img{
  width: 400px;
}
.state {
  font-size: 17px;
    font-weight: 600;
}
.font-bold{
   font-weight: 600;
}
/*-------------------------------------------- Footer section -----------------------------------------------------*/


.footer_section{
	padding: 100px 0;
	    background: #000000a3;
	    position: relative;
}
.footer_one{
	display: flex;
}
.about-footer ,.solution-footer,.news-footer{
	flex-basis: 25%;
}
.footer_section h4{
	color: #fff;
	font-size: 15px;
	font-family: sans-serif;
	font-weight: 900;
    margin-bottom: 24px;
}
.footer_section ul{
	padding-left: 0;
}
.footer_section ul li,.footer_section ul li a{
  list-style: none;
  color: #fff;
  text-decoration: none;
  font-family: sans-serif;
	font-weight: 400;
	cursor: pointer;
}
.footer_section ul li{
	padding-bottom: 6px;
}
.social-footer li{
	display: inline-block;
	margin-right: 8px;
}
.footer-hr{
	color: #fff;
    height: 2px !important;
    margin: 50px 0px;
}

#top_footer .carousel-control-prev{
         left: 90%;
    transition: 90deg;
    top: 16%;
    transform: rotate(90deg);
}

.plustext{

    color:#0d3862;
    font-size:56px!important;
    font-weight: 700;
}

.footer-heading{

  font-size: 14px!important;
  font-weight: 700!important;
}
.newSection {
  display: flex;
  justify-content: center;
}
.barcodeImg{
  width: 50%;
}

.appstoreImg {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  /* display: "flex", justifyContent: "center",marginTop:'10rem'  */
}


.btn-social{
  border-radius: 10px;
background: #179624; 
max-width: 320px;
width: 100%;
height: 82px;
/* padding: 10px 20px; */
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
display: flex;
font-weight: 700;
color: var(--white); 
font-size: 40px;
text-decoration: none;
margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .large-heading{
    font-size: 100px;
  }
  .barcode  img{
    width: 300px;
  }
  .newSection {
    flex-wrap: wrap;
  }
  .barcodeImg{
    width: 90%;
  }
  .appstoreImg{
    margin-top: 0rem;
  }
  .plustext{
    font-size:40px!important;
}

}

@media only screen and (max-width: 768px) { 
  .appstoreImg{
    margin-top: 4rem !important;
  }

}




/*https://xd.adobe.com/view/d1872846-e10c-4558-a107-1d327c36d9d6-84d4/*/

/*https://bbbootstrap.com/snippets/simple-carousel-slider-44469250*/

/*https://cleanup.pictures/*/



.state-heading{

  color: var(--blue); 
  /* //#0d3862 */
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.state-content{
  color: var(--blue);
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 1rem;
}