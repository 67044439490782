#header .navbar-nav a{
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
   padding: 16px 22px;
    line-height: 28px;
    text-decoration: none;
}

#header .navbar-nav a:hover, .slider_home .carousel-control-prev:hover, .slider_home .carousel-control-next:hover{
    background: #277f9a;
}
#header .dropdown:hover>.dropdown-menu {
 display: block;
}

#header .navbar-nav,.dropdown-menu{
    background: #70707094 0% 0% no-repeat padding-box;
 }

#header{
   position: absolute;
   z-index: 100;
   width: 100%;
}
