@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --red: #ED1A3B;
  --blue: #0d3862;
  --white: #ffffff;
  --lightgray: #efefef;
  --black: #000000;
  --gray:#4E4E4E;
  --gray-light: #f8f8f8;
  --green: #179624;
  --primary-font: "Inter", sans-serif;
  --secondry-font: "Inter", sans-serif;
  /* --gap: 3rem; */
  --gap: 80px;
}

html {
  font-family: var(--primary-font);
  font-weight: 400;
  overflow-x: hidden !important;
}

body,
html,
* {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: var(--primary-font) !important;
  /* overflow-x: hidden!important; */
}
a {
  cursor: pointer !important;
}
/* Gap */
.sec-padd-t {
  padding-top: var(--gap);
}

.sec-padd-b {
  padding-bottom: var(--gap);
}

.sec-padd {
  padding: var(--gap) 0 var(--gap) 0;
  /* padding: 50px; */
  /* padding: 80px */
}
.sec-barcode {
  /* padding: 80px; */
  background: #eeeeee;
}
.sec-map{
  padding: 80px
}
.p-space {
  padding: 1rem 0;
}

/* Gap end */

img {
  max-width: 100%;
}

.blue-bg {
  background-color: var(--blue);
}
.sticky-top{
  background: var(--white);
  -webkit-box-shadow: rgb(0 0 0 / 41%) 0px 0px 2px 0px;
  box-shadow: rgb(0 0 0 / 41%) 0px 0px 2px 0px;
  border-bottom: 1px solid #8f8e8e;
}
.custom-nav .navbar-nav a {
  font-size: 15px;
  color: var(--blue) !important;
  line-height: normal;
  padding: 0 !important;
  font-weight: 700;
}
.custom-nav .navbar-nav a svg:last-child {
  height: 1rem;
}
.custom-nav .navbar-nav a:hover{
  color: var(--red) !important;
}
.custom-nav .navbar-nav {
  gap: 1.8rem;
  align-items: center;
}
.custom-nav .navbar-toggler {
  background: #fff;
  outline: none;
  box-shadow: none;
  /* height: 40px; */
  /* width: 40px; */
}
.button-navbar {
  display: flex;
  gap: 1rem;
}
.navbar-toggler-icon {
  height: 30px !important;
  width: 30px !important;
}
.h-button{
  border-radius: 10px;
  padding: 10px 20px;
justify-content: center;
align-items: center; 

font-size: 14px; 
font-weight: 700;
color: var(--white);
text-decoration: none;
}

.green-btn{
  background: var(--green);
}
.gray-btn{
 background: var(--gray);
}

.f-blue {
  color: var(--blue);
}
.heading1 {
  font-size: 42px;
}
.f-red {
  color: var(--red);
}
.w-900 {
  font-weight: 900;
}
.c-para {
  font-size: 18px;
  color: var(--black);
}
.light-card {
  display: flex;
  padding: 30px 13px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  background: var(--ligher-gray, #f8f8f8);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
}
.title-card {
  font-size: 26px;
}
.p-text {
  font-size: 16px;
  color: var(--black);
}
.c-button {
  text-align: center;
  padding: 6px 20px;
  border-radius: 5px;
  background-color: var(--red);
  color: var(--white);
  font-size: 30px;
  font-family: var(--secondry-font) !important;
  text-decoration: none;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}

 
 

.c-button:hover,
.c-button:focus {
  background-color: var(--blue);
}
.bg-pattern-img {
  background: url(../src/assests/images/website/bg-pattern.jpg) center center /
    cover;
}

.blue-ul {
  padding-left: 0;
}
.blue-ul li {
  color: var(--blue);
  font-size: 16px;
}
.join-li {
  font-size: 18px;
  font-weight: 500;
  color: var(--blue);
}
.blue-color {
  background-color: var(--blue);
}
.blue-color:hover,
.blue-color:focus {
  background-color: var(--red);
}
.bg-banner1 {
  background: url(../src/assests/images/website/Banner01.webp) center center /
    cover;
}
.f-white {
  color: var(--white);
}
.f-400 {
  font-weight: 400;
}
.check-ul {
  list-style: none;
}
.check-ul li {
  position: relative;
}
.check-ul li::before {
  content: "";
  position: absolute;
  left: -48px;
  top: 4px;
  background: url(../src/assests/images/website/icon-park.png) center center /
    cover;
  width: 30px;
  height: 30px;
  z-index: 1;
  outline: 5px solid #fff;
  border-radius: 50%;
}
.check-ul li::after {
  content: "";
  position: absolute;
  left: -35px;
  top: 5px;
  height: 100%;
  z-index: -1;
  width: 2px;
  background-color: var(--red);
}
.check-ul li:last-child:after {
  display: none;
}
.heading2 {
  font-size: 26px;
}
.normal-p {
  font-size: 16px;
  color: var(--black);
}
.big-bold {
  font-size: 45px;
  font-weight: 700;
}

.bg-pattern-img2 {
  background: url(../src/assests/images/website/bg-pattern2.jpg) center center /
    cover;
}
.big-red-heading {
  font-size: 70px;
  color: var(--red);
  font-weight: 700;
}
.big-blue-heading {
  font-size: 120px;
  font-weight: 900;
  color: var(--blue);
}
.bg-banner3 {
  background: url(../src/assests/images/website/bgbanner.jpg) center center /
    cover;
}
.sm-haeding {
  font-size: 60px;
}
.large-heading {
  font-size: 120px;
  font-weight: 900;
}
.md-heading {
  font-size: 80px;
}
.border-left {
  position: relative;
}
.border-left::before {
  top: 0;
  left: -20px;
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: var(--blue);
}

.w-700 {
  font-weight: 700;
}
.flex-group {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.flex-group li a svg {
  width: 1.4rem;
}
.flex-group li:first-child a svg {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );

  color: #fff;
  padding: 6px 5px;
  border-radius: 4px;
}
.flex-group li:nth-child(2) a svg {
  fill: #3d5a98;
}
.flex-group li:last-child a svg {
  color: #55acee;
}
.small-p {
  color: var(--black);
  font-size: 12px;
  margin-bottom: 0;
}
.flex-group input {
  margin-top: 0;
}
.custom-radio-red {
  width: 35px !important;
  height: 26px !important;
  box-shadow: none !important;
}
.custom-radio-red:checked {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}
.footer-form {
  margin: 2rem 0;
}
.footer-form input {
  background-color: var(--blue);
  color: var(--white);
  font-size: 18px;
  box-shadow: none !important;
  border-color: var(--blue);
  border-radius: 5px;
  text-align: center;
  padding: 0.8rem;
  height: 30px;
  border: none !important;
  outline: none !important;
  width: 70%;
}
.footer-form input::placeholder {
  color: var(--white);
  font-size: 18px;
}
.footer-form select {
  height: 30px;
  border: none !important;
  outline: none !important;
  color: #6c6c6c;
  font-size: 18px;
  background-color: var(--gray-light);
  border-radius: 5px;
  text-align: center;
  padding: 0 rem;
  width: 30%;
  height: 50px;
}
.footer-form select option {
  padding: 0.8rem;
  font-size: 18px;
  text-align: center;
  color: #6c6c6c;
}

 
.condition-div {
  border-radius: 6px;
  background-color: var(--white);
  padding: 5px;
  gap: 1rem;
}
.condition-div .custom-radio-red {
  width: 22px !important;
  height: 13px !important;
}
.black-f {
  color: var(--black);
}
.small-font {
  font-size: 14px !important;
}
.middle-size {
  height: 50px;
}

.title-p{
  /*     var(--blue); */
  color:  white;  
text-align: center; 
font-size: 30px; 
font-weight: 400; 
}
.big-font,.big-font span{
  color: white;
  font-size: 80px; 
  font-weight: 900; 
}
.big-font span{
  color: var(--red);
}
.banner-section {
  text-align: center;
  /* padding: 80px; */
  background: black;
}
.banner-section h5{
  color: var(--blue);
text-align: center; 
font-size: 40px; 
font-weight: 700; 
}
.banner-section h3,.banner-section h3 span{
  color:white;
  font-size: 64px; 
  font-weight: 700; 
}
.banner-section h3 span{
  color: var(--red);
}
.banner-section h4{
color:white;
font-size: 40px; 
font-weight: 400; 
}
.btn-c{
  border-radius: 10px;
background: #179624; 
max-width: 220px;
width: 100%;
height: 82px;
/* padding: 10px 20px; */
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
display: flex;
font-weight: 700;
color: var(--white); 
font-size: 40px;
text-decoration: none;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Adds spacing between buttons */
  flex-wrap: wrap; /* Use `wrap` if buttons need to stack on smaller screens */
}
.bg-pattern{
  background: url(./../src/assests/images/website/pattern.webp) center center /cover;
}
.medium-p{
  color: var(--blue); 
font-size: 35px; 
font-weight: 700; 
}
.end-text{
text-align: right;
margin-top: -0.6rem;
}
.medium-heading{
  color: var(--green); 
font-size: 52px; 
font-weight: 700; 
}
.medium-heading span{
  color: var(--blue);
  font-size: 52px; 
font-weight: 700;
}
.second-p{
  color: var(--blue); 
font-size: 30px; 
font-weight: 700; 
}
.align-bottom{
  align-items: self-end;
}
.right-div img{
 width: 100%;
}
.flex-p span{
  font-weight: 900;
}
.flex-p,.flex-p span, .flex-p a{
  color: var(--black); 
font-size: 17px; 
font-weight: 700; 
text-align: center;
}
.flex-p a{
  color: var(--red) !important
}
.flex-p a:hover,.flex-p a:focus{
  color: var(--red) !important
}
.c-heading{
  color: var(--blue); 
font-size: 64px; 
font-weight: 700;
}
.text-p{
  color: var(--blue);
text-align: center; 
font-size: 14px; 
font-weight: 400; 
}
.small-p{
  color: var(--blue);
text-align: center; 
font-size: 20px; 
font-weight: 400; 
}
.bg-gray{ 
background: #111;
}
.copy-p{
  color: var(--white); 
font-size: 14px; 
font-weight: 400; 
}
.flex-footer{
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
.flex-footer img{
  height: 45px;
  width: 100%;
  object-fit: contain;
}
footer, footer li a{
  color: var(--white); 
  text-decoration: none;
}
footer  ul{
  list-style: none;
  padding: 0;

}
footer li{
  line-height: 1rem;
}
footer h4, footer li a{ 
  font-size: 14px; 
  font-weight: 700; 
}
footer li a{
  font-weight: 400;
}

  .ul-col {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  .copy-p{
    border-top: 1px solid rgb(78 78 78 / 50%);
    padding-top: 0.8rem;
  }
  .navbar-toggler {
    box-shadow: none !important;
    outline: none !important;
  }
  @media(max-width:992px){
    .custom-nav .navbar-nav {
      gap: 0.8rem;
      align-items: start;
      margin-top: 1rem !important;

      margin-bottom: 1rem !important;
  }
  .big-font, .big-font span{
    font-size: 50px;
  }
  .banner-section h3, .banner-section h3 span{
    font-size: 45px;
  }
  .banner-section h4{
    font-size: 32px;
  }
  }
@media only screen and (max-width: 780px) {
  .aftergame {
    font-size: 95px !important;
  }

  .celebrate {
    font-size: 48px !important;
  }
  .flex-center.mt-5{
    margin-top: 2rem !important;
  }
 body .ms-4{
    margin-left: 0 !important;
  }
  .align-bottom .col-md-6:first-child img{ 
    height: 280px;
    width: 100%;
    object-fit: contain;
margin-top: 1.5rem;
  }
  .align-bottom .col-md-6:last-child img{
    height: 80px;
    width: 100%;
    object-fit: contain;
    margin-top: 15px;
  }
  .c-heading{
font-size: 45px;
  }
  .btn-c .btn-social{
    height: 60px;
    font-size: 32px;
  }
.footer-social{
    margin-bottom: 1.5rem;
    margin-top: -1rem;
  }
  .right-div{
    margin-top: 1.5rem !important;
  }
}


 a:hover{

    color: white!important;

}
 

@media (max-width: 768px) {
  .flex-center {
    flex-direction: column; /* Stack buttons vertically */
    gap: 0.5rem; /* Reduce spacing for smaller screens */
  }
}

@media only screen and (max-width: 767px) {
  .banner-section{
    padding: 40px;
  }
  .sec-map{
    padding: 50px
  }
  .sec-barcode {
    padding: 30px;
  }
  .sec-padd{
    padding: 40px 0;
  }
}


@media only screen and (max-width: 600px) {
 
.title-p{
font-size: 20px; 
}

}