
.footer_section{
    padding: 100px 0;
        background: #000000a3;
        position: relative;
  }
  .footer_one{
    display: flex;
  }
  .about-footer ,.solution-footer,.news-footer{
    flex-basis: 25%;
  }
  .footer_section h4{
    color: #fff;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 900;
      margin-bottom: 24px;
  }
  .footer_section ul{
    padding-left: 0;
  }
  .footer_section ul li,.footer_section ul li a{
    list-style: none;
    color: #fff;
    text-decoration: none;
    font-family: sans-serif;
    font-weight: 400;
    cursor: pointer;
  }
  .footer_section ul li{
    padding-bottom: 6px;
  }
  .social-footer li{
    display: inline-block;
    margin-right: 8px;
  }
  .footer-hr{
    color: #fff;
      height: 2px !important;
      margin: 50px 0px;
  }
  
  #top_footer .carousel-control-prev{
           left: 90%;
      transition: 90deg;
      top: 16%;
      transform: rotate(90deg);
  }
  .slider_home .carousel-control-next-icon, .slider_home .carousel-control-prev-icon{
  width: 1rem;
      height: 1rem;
  }
  .slider_home .carousel-control-prev, .slider_home .carousel-control-next{
        width: 35px;
      height: 35px;
      background: #707070e0;
      top: 30%;
          opacity: 1;
  }